import React from 'react';
import Noise from '../../components/Noise';

export default function BaseLayout({ children }) {
	return (
		<>
			{children}
			<Noise />
		</>
	);
}
